import React, { useState, useEffect, useRef } from 'react';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT as string;
const username = localStorage.getItem('username');

interface AudioPlayerProps {
  audioId: string;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ audioId }) => {
  const [loadingProgress, setLoadingProgress] = useState<number>(0);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    const updateLoadingProgress = () => {
      if (audioRef.current) {
        const buffer = audioRef.current.buffered;
        if (buffer.length) {
          const loaded = buffer.end(buffer.length - 1);
          const total = audioRef.current.duration;
          const progress = (loaded / total) * 100;
          setLoadingProgress(progress);
        }
      }
    };

    if (audioRef.current) {
      audioRef.current.addEventListener('progress', updateLoadingProgress);
      audioRef.current.addEventListener('canplaythrough', () => setLoadingProgress(100));
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('progress', updateLoadingProgress);
        audioRef.current.removeEventListener('canplaythrough', () => setLoadingProgress(100));
      }
    };
  }, [audioId]);

  return (
    <div>
      <audio
        style={{ width: '100%' }}
        ref={audioRef}
        src={`https://${apiEndpoint}/getrecording?recording_name=${audioId}`}
        preload="auto"
        controls
      />
      {loadingProgress < 100 && (
        <LinearProgress variant="determinate" value={Math.round(loadingProgress)} />
      )}
    </div>
  );
};

export default AudioPlayer;
