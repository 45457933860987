import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Box, Container, Paper } from '@mui/material';
//import LogoPlaceholder from './LogoPlaceholder'; // Assume you have a component for the logo

const AuthButton = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.isAuthenticated && auth.user) {
      console.log("User is authenticated:", auth.user);
      localStorage.setItem('username', auth.user?.profile?.preferred_username);
      navigate('/dashboard'); // Navigate to dashboard if authenticated
    }
  }, [auth.isAuthenticated, auth.user, navigate]);

  const handleLogin = () => {
    auth.signinRedirect();
  };

  const handleLogout = () => {
    auth.signoutRedirect();
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ mt: 5, p: 4 }}>
        <AppBar position="static">
          <Toolbar>
            {/* <LogoPlaceholder sx={{ flexGrow: 1 }} /> Placeholder for the logo */}
            <img src={`${process.env.PUBLIC_URL}/Powervoice.png`} alt="Description" height="50px"/>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, marginLeft: 2 }}>
              Powervoice
            </Typography>
            {auth.isAuthenticated ? (
              <Button color="inherit" onClick={handleLogout}>Sign Out</Button>
            ) : (
              <Button color="inherit" onClick={handleLogin}>Sign In</Button>
            )}
          </Toolbar>
        </AppBar>
        <Box sx={{ mt: 2 }}>
          {auth.isAuthenticated ? (
            <Typography variant="body1">Welcome, {auth.user?.profile?.name || 'User'}!</Typography>
          ) : (
            <Typography variant="body1">Please log in to continue.</Typography>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default AuthButton;
