import React, { useState, useEffect, useRef, ChangeEvent, KeyboardEvent } from 'react';
import {
  Container,
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  LinearProgress,
  Typography,
  Grid,
  Box,
  Toolbar
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactMarkdown from 'react-markdown';
import SendIcon from '@mui/icons-material/Send';
import Mermaid from './Mermaid';
import CustomAppBar from './NavBar';
import { useTheme, Theme } from '@mui/material/styles';

interface ChatMessage {
  id: number;
  question: string;
  answers: Array<{ type: string; content: string }>;
  requestTime: number;
  processingTime: number | string;
}

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT as string;
const username = localStorage.getItem('username');

const ChatBot: React.FC = () => {
  const [inputText, setInputText] = useState<string>('');
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
  const websocketRef = useRef<WebSocket | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const theme = useTheme();

  console.log('ChatBot is re-rendering');

  useEffect(() => {
    const ws = new WebSocket('wss://' + apiEndpoint + '/askws');
    websocketRef.current = ws;

    ws.onopen = () => {
      console.log('WebSocket connected');
    };

    ws.onerror = (event) => {
      setIsLoading(false);
      console.error('WebSocket error:', event);
    };

    return () => {
      if (websocketRef.current) {
        websocketRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (websocketRef.current) {
      websocketRef.current.onmessage = (event: MessageEvent) => {
        const data = JSON.parse(event.data);
        setIsLoading(false);

        if (data.type === 'start') {
          // Start processing
        } else if (data.type === 'end') {
          // End processing, calculate processing time
          setChatHistory((prevChatHistory) =>
            prevChatHistory.map((chat) => {
              if (chat.id === data.id) {
                return {
                  ...chat,
                  processingTime: (Date.now() - chat.requestTime) / 1000,
                };
              }
              return chat;
            })
          );
        } else {
          // Regular message
          setChatHistory((prevChatHistory) =>
            prevChatHistory.map((chat) => {
              if (chat.id === data.id) {
                const updatedAnswers = Array.isArray(chat.answers) ? [...chat.answers, data] : [data];
                return { ...chat, answers: updatedAnswers };
              }
              return chat;
            })
          );
        }
      };
    }
  }, []);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value);
  };

  const handleSendMessage = () => {
    if (inputText.trim() && websocketRef.current && !isLoading) {
      const requestTime = Date.now();
      const questionId = chatHistory.length;
      setChatHistory((prevChatHistory) => [
        {
          id: questionId,
          question: inputText,
          answers: [],
          requestTime: requestTime,
          processingTime: '...', // Placeholder, updated on receiving the response
        },
        ...prevChatHistory,
      ]);

      websocketRef.current.send(
        JSON.stringify({
          id: questionId,
          question: inputText,
          username: username,
        })
      );
      setInputText('');
      setIsLoading(true);
    }
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const getAccordionSummaryStyle = (theme: Theme) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#f7f7f7' : theme.palette.grey[800],
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
  });

  const accordionSummaryStyle = getAccordionSummaryStyle(theme);

  return (
    <Box sx={{ display: 'flex' }}>
      <CustomAppBar />
      <Toolbar />
      <Container sx={{ pt: (theme) => theme.spacing(8) }}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs>
            <TextField
              label="Your question"
              variant="outlined"
              size="small"
              margin="normal"
              color="info"
              fullWidth
              value={inputText}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              disabled={isLoading}
            />
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleSendMessage} disabled={isLoading}>
              <SendIcon />
            </Button>
          </Grid>
        </Grid>
        {isLoading && <LinearProgress />}
        {chatHistory.map((chat, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={accordionSummaryStyle}>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Typography sx={{ textAlign: 'left', flex: 1 }}>{chat.question}</Typography>
                <Typography color="text.secondary">
                  Sent: {new Date(chat.requestTime).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false })} | Processing Time: {typeof chat.processingTime === 'number' ? `${chat.processingTime} s` : chat.processingTime}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails style={{ textAlign: 'left' }}>
              {chat.answers.map((answer, answerIndex) => {
                if (answer.type === 'text') {
                  return <ReactMarkdown key={answerIndex}>{answer.content}</ReactMarkdown>;
                } else if (answer.type === 'mermaid') {
                  return <Mermaid key={answerIndex} chart={answer.content} />;
                } else if (answer.type === 'apex') {
                  // Render Apex chart here
                  return null; // Placeholder until implementation
                }
                return null; // Fallback for unsupported answer types
              })}
              {chat.answers.length === 0 && <LinearProgress color="secondary" />}
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Box>
  );
};

export default ChatBot;