import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { purple,pink } from '@mui/material/colors';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const username = localStorage.getItem('username');

export default function MeetingOptionsMenu({ meetingUuid, onRename, setIsModalOpen }) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Function to handle rename action
    const handleRename = () => {
        // Close the menu
        handleClose();
        // Trigger the rename function passed from the parent component
        onRename(meetingUuid);
    };

    // Function to handle file download
    const handleDownload = () => {
        // Close the menu
        handleClose();

        // Define the URL of your FastAPI endpoint
        const apiUrl = `https://${apiEndpoint}/get_full_audio?username=${username}&meeting_uuid=${encodeURIComponent(meetingUuid)}`;

        // Fetch the file
        fetch(apiUrl, {
            method: 'GET',
        })
        .then(response => response.blob()) // Convert the response to a blob
        .then(blob => {
            // Create a link element
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'audio_file.mp3'; // Set the file name
            document.body.appendChild(a);
            a.click(); // Trigger the download
            a.remove(); // Clean up
            window.URL.revokeObjectURL(url); // Revoke the object URL
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    // Function to handle re-summarize action
    const handleResummarize = () => {
        // Close the menu
        handleClose();
        console.log('here');
        // Call the API
        const apiUrl = `https://${apiEndpoint}/summarize/?username=${username}&meeting_uuid=${encodeURIComponent(meetingUuid)}`;
        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            // Handle the response data as needed
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    // Function to handle re-summarize action
    const handleDelete = () => {
        // Close the menu
        handleClose();
        console.log('here');
        // Call the API
        const apiUrl = `https://${apiEndpoint}/delete/?username=${username}&meeting_uuid=${encodeURIComponent(meetingUuid)}`;
        fetch(apiUrl, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            // Handle the response data as needed
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
      };


    return (
        <div>
            <Tooltip title="Options">
                <IconButton onClick={handleClick}>
                    <SettingsIcon
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        color="action"
                        fontSize="small"
                        />
                </IconButton>
            </Tooltip>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleOpenModal}>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <NoteAddIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Meeting Details" />
                        </ListItemButton>
                    </ListItem>
                </MenuItem>
                <MenuItem onClick={handleDownload}>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <FileDownloadIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Download Recording" />
                        </ListItemButton>
                    </ListItem>
                </MenuItem>
                <MenuItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={handleRename}>
                            <ListItemIcon>
                                <DriveFileRenameOutlineIcon fontSize='small'  sx={{ color: purple[500] }} />
                            </ListItemIcon>
                            <ListItemText primary="Rename" />
                        </ListItemButton>
                    </ListItem>
                </MenuItem>
                <MenuItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={handleResummarize}>
                            <ListItemIcon>
                                <SummarizeIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Summarize" />
                        </ListItemButton>
                    </ListItem>
                </MenuItem>
                <MenuItem onClick={handleDelete}>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <DeleteIcon fontSize='small'  sx={{ color: pink[500] }} />
                            </ListItemIcon>
                            <ListItemText primary="Delete" />
                        </ListItemButton>
                    </ListItem>
                </MenuItem>
            </Menu>
        </div>
    );
}
