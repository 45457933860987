import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Chip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',  // This should use the theme's paper background color
  color: 'text.primary',  // Ensure text color also follows the theme
  border: '2px solid',
  borderColor: 'divider',  // Border should adapt to the theme
  boxShadow: 24,
  p: 4,
};

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const username = localStorage.getItem('username');

export default function MeetingInfoModal({ open, onClose, meetingUuid, meetingParticipants }) {
  const [participant, setParticipant] = useState('');
  const [participants, setParticipants] = useState([]);
  const [suggestedParticipants, setSuggestedParticipants] = useState([]);

  useEffect(() => {
    if (open) {
      // Clear the lists when the modal opens or when the meetingUuid changes
      setParticipants(meetingParticipants || []);
      setSuggestedParticipants([]);

      const fetchSuggestedParticipants = async () => {
        try {
          const response = await fetch(
            `https://${apiEndpoint}/participan_suggestion?username=${username}&meeting_uuid=${meetingUuid}`
          );
          if (!response.ok) {
            throw new Error('Failed to fetch suggested participants');
          }
          const data = await response.json();
          setSuggestedParticipants(data);
        } catch (error) {
          console.error('Error fetching suggested participants:', error);
        }
      };

      fetchSuggestedParticipants();
    }
  }, [open, meetingUuid]);  // Trigger effect when modal opens or meetingUuid changes

  const handleAddParticipant = () => {
    if (participant.trim()) {
      setParticipants([...participants, participant]);
      setParticipant('');
    }
  };

  const handleRemoveParticipant = (nameToRemove) => {
    setParticipants(participants.filter(name => name !== nameToRemove));
  };

  const handleSuggestedClick = (name) => {
    if (!participants.includes(name)) {
      setParticipants([...participants, name]);
    }
  };

  const handleSaveParticipants = async () => {
    try {
      const response = await fetch(`https://${apiEndpoint}/save_participants?username=${username}&meeting_uuid=${meetingUuid}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          participants
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to save participants');
      }

      // Close the modal if the save is successful
      onClose();
    } catch (error) {
      console.error('Error saving participants:', error);
      // You can handle error display or other actions here
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-title" variant="h6" component="h2">
          Add Participants
        </Typography>
        <TextField
          fullWidth
          label="Add Participant"
          value={participant}
          onChange={(e) => setParticipant(e.target.value)}
          margin="normal"
        />
        <IconButton
          color="primary"
          onClick={handleAddParticipant}
          disabled={!participant.trim()}
        >
          <AddIcon />
        </IconButton>
        <Box mt={2} mb={2}>
          {participants.map((name, index) => (
            <Chip
              key={index}
              label={name}
              onDelete={() => handleRemoveParticipant(name)}
              sx={{ m: 0.5 }}
            />
          ))}
        </Box>
        <Typography variant="subtitle1" component="h3">
          Suggested Participants:
        </Typography>
        <Box>
          {suggestedParticipants.map((name, index) => (
            <Chip
              key={index}
              label={name}
              onClick={() => handleSuggestedClick(name)}
              sx={{ m: 0.5 }}
              clickable
              color="primary"
            />
          ))}
        </Box>
        <Box mt={3} textAlign="right">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveParticipants}
            sx={{ mr: 1 }}
          >
            Save
          </Button>
          <Button variant="contained" onClick={onClose}>
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
