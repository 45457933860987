import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { v4 as uuidv4 } from 'uuid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import Box from '@mui/material/Box';
import { TransitionProps } from '@mui/material/transitions';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT as string;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props: any) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const AudioRecorderComponent: React.FC = () => {
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [meetingName, setMeetingName] = useState<string>('');
  const [meetingUUID, setMeetingUUID] = useState<string>(uuidv4());
  const [dialogOpen, setDialogOpen] = useState<boolean>(true);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const wsRef = useRef<WebSocket | null>(null);
  const username = localStorage.getItem('username');

  const handleMeetingNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMeetingName(event.target.value);
  };

  const startRecording = async () => {
    console.log('Before Start Meeting ID = ' + meetingUUID);
    if (!navigator.mediaDevices || !MediaRecorder || !wsRef.current) {
      console.error('Media devices, MediaRecorder, or WebSocket not supported on this browser or WebSocket not connected.');
      return;
    }

    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN && meetingName) {
      wsRef.current.send(
        JSON.stringify({
          username: username,
          meetingName: meetingName,
          meetingUUID: meetingUUID,
          type: 'start',
        })
      );
    }

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const mediaRecorder = new MediaRecorder(stream);
    mediaRecorderRef.current = mediaRecorder;

    mediaRecorder.ondataavailable = (event: BlobEvent) => {
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        wsRef.current.send(event.data);
      }
    };

    mediaRecorder.start(1000);
    setIsRecording(true);
  };

  const stopRecording = () => {
    setMeetingUUID(uuidv4());
    console.log('Stop Meeting ID = ' + meetingUUID);

    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN && meetingName) {
      wsRef.current.send(
        JSON.stringify({
          username: username,
          meetingName: meetingName,
          meetingUUID: meetingUUID,
          type: 'stop',
        })
      );
    }

    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());
    }
    setIsRecording(false);
  };

  useEffect(() => {
    wsRef.current = new WebSocket('wss://' + apiEndpoint + '/audiows');

    wsRef.current.onopen = () => {
      console.log('WebSocket connected');
    };

    wsRef.current.onclose = () => console.log('WebSocket disconnected');

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, []);

  const handleClose = () => {
    if (!isRecording) {
      setDialogOpen(false);
    } else {
      alert('Please stop the recording before closing.');
    }
  };

  return (
    <React.Fragment>
      <Dialog open={dialogOpen} TransitionComponent={Transition} PaperComponent={PaperComponent} onClose={handleClose}>
        <DialogTitle id="draggable-dialog-title">Audio Recording</DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
            <Button variant="contained" onClick={startRecording} disabled={isRecording}>
              Start Recording
            </Button>
            <Button variant="contained" onClick={stopRecording} disabled={!isRecording}>
              Stop Recording
            </Button>
            <TextField
              label="Meeting Name"
              variant="outlined"
              value={meetingName}
              onChange={handleMeetingNameChange}
              disabled={isRecording}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AudioRecorderComponent;