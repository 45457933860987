import React, { useState, useEffect, useRef, Dispatch, SetStateAction, MutableRefObject } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import MeetingOptionsMenu from './MeetingOptionsMenu';
import TextField from '@mui/material/TextField';
import { LinearProgress } from '@mui/material';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT as string;

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(0),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const CustomListItemButton = styled(ListItemButton)(({ theme }) => ({
    '&:hover .checkbox': {
        visibility: 'visible',
    },
}));

const HiddenCheckbox = styled(Checkbox)(({ theme }) => ({
    visibility: 'hidden',
    marginLeft: theme.spacing(2), // Add some margin to the left for better alignment
    '&.Mui-checked': {
        visibility: 'visible',
    },
}));

interface CustomizedAccordionsProps {
    setSelectedMeetingUuid: Dispatch<SetStateAction<string | null>>;
    setSelectedMeetingParticipants: Dispatch<SetStateAction<string[]>>;
    meetingMergeRef: MutableRefObject<(() => void) | null>;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  }

interface Meeting {
    meeting_uuid: string;
    meeting_name: string;
    meeting_from: string;
    meeting_to: string;
    participants: string[];
    // Add other relevant fields
}

export default function CustomizedAccordions({
    setSelectedMeetingUuid,
    setSelectedMeetingParticipants,
    meetingMergeRef,
    setIsModalOpen,
}: CustomizedAccordionsProps) {
    const [expanded, setExpanded] = useState<string | false>('');
    const [meetingsByDate, setMeetings] = useState<Record<string, Meeting[]>>({});
    const [selectedMeetings, setSelectedMeetings] = useState<string[]>([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const isMounted = useRef(true);
    const scrollableDivRef = useRef<HTMLDivElement>(null);
    const [isScrollbarVisible, setIsScrollbarVisible] = useState(false);

    const username = localStorage.getItem('username');

    const handleMenuItemClick = (meetingUuid: string, participants: string[]) => {
        setSelectedMeetingUuid(meetingUuid);
        setSelectedMeetingParticipants(participants);
    };

    const mergeMeetings = () => {
        console.log(selectedMeetings);
        fetch(`https://${apiEndpoint}/merge?username=${username}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ meetings: selectedMeetings }),
        })
            .then((response) => response.json())
            .then((data) => {
                handleMenuItemClick(data.meeting_uuid,data.participants);
                setSelectedMeetings([]); // Clear the selected meetings list
                fetchData(page); // Pass the current page to refetch the latest data
            })
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        meetingMergeRef.current = mergeMeetings;
    }, [meetingMergeRef]);

    const fetchData = async (page: number) => {
        setIsLoading(true);
        console.log('Fetching data for page:', page);

        try {
            const response = await fetch(`https://${apiEndpoint}/listmeetings/?username=${username}&page=${page}&page_size=50`);
            const data: Record<string, Meeting[]> = await response.json();

            if (isMounted.current) {
                if (Object.keys(data).length === 0) {
                    setHasMore(false);
                    console.log('No more data to load.');
                } else {
                    setMeetings((prevMeetings) => {
                        let updatedMeetings = { ...prevMeetings };
                        for (const date in data) {
                            if (data.hasOwnProperty(date)) {
                                updatedMeetings[date] = updatedMeetings[date]
                                    ? [...updatedMeetings[date], ...data[date]]
                                    : data[date];
                            }
                        }
                        return updatedMeetings;
                    });
                }
            }
        } catch (error) {
            console.error('Fetch error:', error);
        } finally {
            if (isMounted.current) {
                setIsLoading(false);
                console.log(`Fetch completed for page ${page}, isLoading set to false`);
            }
        }
    };

    const loadMore = () => {
        console.log('Triggered loadMore', isLoading, hasMore, page);
        if (!isLoading && hasMore) {
            console.log('Loading more data for page:', page);
            fetchData(page);
            setPage((prevPage) => prevPage + 1);
        }
    };

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleCheckboxChange = (meetingUuid: string) => {
        setSelectedMeetings((prevSelectedMeetings) =>
            prevSelectedMeetings.includes(meetingUuid)
                ? prevSelectedMeetings.filter((uuid) => uuid !== meetingUuid)
                : [...prevSelectedMeetings, meetingUuid],
        );
    };

    const formatTime = (timeStr: string) => {
        return timeStr.replace(/(\d{2})(\d{2})(\d{2})/, '$1:$2:$3');
    };

    const formatDate = (date: string) => {
        return date.replace(/(\d{4})(\d{2})(\d{2})/, '$2/$3/$1');
    };

    const [editingMeetingUuid, setEditingMeetingUuid] = useState<string | null>(null);
    const [newMeetingName, setNewMeetingName] = useState<string>('');

    const handleRename = (meetingUuid: string) => {
        console.log('handleRename');
        setEditingMeetingUuid(meetingUuid);
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log('handleNameChange');
        setNewMeetingName(event.target.value);
    };

    const handleNameSubmit = (meetingUuid: string) => {
        const inputElement = document.getElementById(`meeting-input-${meetingUuid}`) as HTMLInputElement;
        const newName = inputElement.value.trim();

        if (!newName) return;

        fetch(`https://${apiEndpoint}/rename?username=${username}&meeting_uuid=${encodeURIComponent(meetingUuid)}&meeting_name=${newName}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Rename success:', data);
                fetchData(page); // Refetch the data for the current page
            })
            .catch((error) => console.error('Rename error:', error));

        setEditingMeetingUuid(null);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, meetingUuid: string) => {
        console.log('handleKeyDown');
        if (event.key === 'Enter') {
            handleNameSubmit(meetingUuid);
        }
    };

    useEffect(() => {
        const checkScrollbar = () => {
            const scrollableDiv = scrollableDivRef.current;
            if (scrollableDiv) {
                setIsScrollbarVisible(scrollableDiv.scrollHeight > scrollableDiv.clientHeight);
            }
            if (!isScrollbarVisible && hasMore) {
                loadMore();
            }
        };

        checkScrollbar();
    }, [meetingsByDate]);

    return (
        <div id="scrollableDiv" ref={scrollableDivRef} style={{ height: "100%", overflow: "auto" }}>
            <InfiniteScroll
                dataLength={Object.keys(meetingsByDate).length}
                next={loadMore}
                hasMore={hasMore}
                loader={<div className="loader" key={0}><LinearProgress /></div>}
                scrollableTarget="scrollableDiv"
            >
                {Object.keys(meetingsByDate).sort((a, b) => b.localeCompare(a)).map((date) => (
                    <Accordion key={date} expanded={expanded === date} onChange={handleChange(date)}>
                        <AccordionSummary aria-controls={`panel${date}d-content`} id={`panel${date}d-header`}>
                            <Typography>Date: {formatDate(date)} - Total Meetings: {meetingsByDate[date].length}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                <nav aria-label="main mailbox folders">
                                    <List>
                                        {meetingsByDate[date].map((meeting, index) => (
                                            <ListItem disablePadding key={index} onClick={() => handleMenuItemClick(meeting.meeting_uuid,meeting.participants)}>
                                                <CustomListItemButton>
                                                    <MeetingOptionsMenu meetingUuid={meeting.meeting_uuid} onRename={handleRename} setIsModalOpen={setIsModalOpen}/>
                                                    {editingMeetingUuid === meeting.meeting_uuid ? (
                                                        <TextField
                                                            id={`meeting-input-${meeting.meeting_uuid}`}
                                                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(e, meeting.meeting_uuid)} // Submit on Enter
                                                            autoFocus
                                                            fullWidth
                                                            variant="standard"
                                                        />
                                                    ) : (
                                                        <ListItemText primary={meeting.meeting_name} secondary={`${formatTime(meeting.meeting_from)} to ${formatTime(meeting.meeting_to)}`} />
                                                    )}
                                                    <HiddenCheckbox
                                                        className="checkbox"
                                                        checked={selectedMeetings.includes(meeting.meeting_uuid)}
                                                        onChange={() => handleCheckboxChange(meeting.meeting_uuid)}
                                                        edge="end"
                                                    />
                                                </CustomListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                </nav>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </InfiniteScroll>
        </div>
    );
}