import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import CustomizedAccordions from './AccordionMenu';
import MeetingSummary from './MeetingSummary';
import SpeedDialTooltipOpen from './SpeedDial';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import AudioPlayer from './AudioPlayer';
import { grey } from '@mui/material/colors';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import AudioRecorderComponent from './AudioRecorderComponent';
import CustomAppBar from './NavBar';
import Paper from '@mui/material/Paper';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MeetingInfoModal from './MeetingInfoModal';

const drawerWidth = 360;
const rightDrawerWidth = 240;
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT as string;

interface AudioFile {
  name: string;
  segment_from: string;
  segment_to: string;
}

export default function AppDrawer() {
  const [selectedMeetingUuid, setSelectedMeetingUuid] = useState<string | null>(null);
  const [selectedMeetingParticipants, setSelectedMeetingParticipants] = useState<string[]>([]);
  const [audioFiles, setAudioFiles] = useState<AudioFile[]>([]);
  const [audioId, setAudioId] = useState<string | null>(null);
  const [showRecorder, setShowRecorder] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const meetingMergeRef = useRef<(() => void) | null>(null);

  const toggleDrawer = () => {
    setOpen((prevState) => !prevState);
  };

  const toggleRecorder = () => {
    setShowRecorder((prevShow) => !prevShow);
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;

    const fetchData = () => {
      if (selectedMeetingUuid) {
        fetch(`https://${apiEndpoint}/recordings/?meeting_uuid=${selectedMeetingUuid}&username=${localStorage.getItem('username')}`)
          .then((response) => response.json())
          .then((data) => {
            const sortedRecordings = [...data.recordings].sort((a: AudioFile, b: AudioFile) => {
              return a.segment_from.localeCompare(b.segment_from);
            });
            setAudioFiles(sortedRecordings);
          })
          .catch((error) => console.error('Error fetching audio files:', error));
      }
    };

    fetchData();

    intervalId = setInterval(fetchData, 60000);

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [selectedMeetingUuid]);

  const handleCloseAudio = () => {
    setAudioId(null);
  };

  const formatTime = (timeStr: string): string => {
    return timeStr.replace(/(\d{2})(\d{2})(\d{2})/, '$1:$2:$3');
  };

  const handleAudio = (id: string) => {
    setAudioId(id);
  };

  const formatAudioFileName = (fileName: string): string => {
    const regex = /_(\d{8})_(\d{6})_to_(\d{8})_(\d{6})\.mp3$/;
    const match = fileName.match(regex);

    if (match) {
      const startTime = match[2];
      const endTime = match[4];

      const formattedStartTime = `${startTime.slice(0, 2)}:${startTime.slice(2, 4)}:${startTime.slice(4, 6)}`;
      const formattedEndTime = `${endTime.slice(0, 2)}:${endTime.slice(2, 4)}:${endTime.slice(4, 6)}`;

      return `Audio Segment From: ${formattedStartTime} To: ${formattedEndTime}`;
    }

    return 'Invalid file name';
  };

  const handleSpeedDialMerge = () => {
    if (meetingMergeRef.current) {
      meetingMergeRef.current();
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box sx={{ bgcolor: 'background.default', display: 'flex' }}>
      {showRecorder && <AudioRecorderComponent />}
      <CustomAppBar />
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ bgcolor: 'background.default', overflow: 'auto' }}>
        <CustomizedAccordions
            setSelectedMeetingUuid={setSelectedMeetingUuid}
            setSelectedMeetingParticipants={setSelectedMeetingParticipants}
            meetingMergeRef={meetingMergeRef}
            setIsModalOpen={setIsModalOpen}
          />
        </Box>
        <SpeedDialTooltipOpen toggleRecorder={toggleRecorder} mergeSelected={handleSpeedDialMerge} />
      </Drawer>
      <Box sx={{ flexGrow: 1 }}>
        <Toolbar />
        {audioId && (
          <Box sx={{ p: 2, bgcolor: 'background.default' }}>
            <Paper elevation={3} sx={{ padding: (theme) => theme.spacing(2) }}>
              <Grid container spacing={2}>
                <Grid xs={11}>
                  <Typography variant="subtitle1" noWrap component="div">
                    {formatAudioFileName(audioId)}
                  </Typography>
                </Grid>
                <Grid xs={1}>
                  <IconButton onClick={handleCloseAudio} aria-label="close">
                    <Close />
                  </IconButton>
                </Grid>
                <Grid xs={12}>
                  <AudioPlayer audioId={audioId} />
                </Grid>
              </Grid>
            </Paper>
          </Box>
        )}
        <MeetingSummary meeting_uuid={selectedMeetingUuid} setAudioId={setAudioId} />
        <MeetingInfoModal open={isModalOpen} onClose={handleCloseModal} meetingUuid={selectedMeetingUuid} meetingParticipants={selectedMeetingParticipants}/>
      </Box>
      <Box sx={{ flexGrow: 0, p: 0, textAlign: 'left', justifyContent: 'flex-end' }}>
        <IconButton
          onClick={toggleDrawer}
          sx={{
            position: 'fixed',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 1201,
            backgroundColor: 'primary.main',
            color: 'white',
            '&:hover': {
              backgroundColor: 'primary.dark',
            },
          }}
        >
          {open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
        <Drawer
          sx={{
            flexShrink: 0,
            width: open ? rightDrawerWidth : 0,
            '& .MuiDrawer-paper': { width: rightDrawerWidth },
          }}
          variant="persistent"
          anchor="right"
          open={open}
        >
          <Toolbar />
          <Divider />
          <List dense>
            {audioFiles.map((file) => (
              <ListItem key={file.name} disablePadding>
                <ListItemButton onClick={() => handleAudio(file.name)}>
                  <ListItemIcon>
                    <PlayCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary={`${formatTime(file.segment_from)} to ${formatTime(file.segment_to)}`} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
      </Box>
    </Box>
  );
}
