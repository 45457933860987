import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Box from '@mui/material/Box';
import ChatBot from './components/ChatBot';
import AppDrawer from './components/AppDrawer';
import AuthButton from './components/AuthButton';
import { useAuth } from "react-oidc-context";
import './App.css'; // Importing App.css
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import ColorModeContext from './components/ColorModeContext';

function App() {
  const auth = useAuth();
  
  // Define a component to handle the loading, error, or sign-in/out status
  const AuthStatus = () => {
    // if (auth.isLoading) {
    //   return <div>Loading...</div>;
    // }
    if (auth.error) {
      return <div>Oops... {auth.error.message}</div>;
    }
    if (auth.activeNavigator === "signinSilent") {
      return <div>Signing you in...</div>;
    }
    if (auth.activeNavigator === "signoutRedirect") {
      return <div>Signing you out...</div>;
    }
    // If none of the above, return null to render nothing for this component
    return null;
  };

  return (
    <Router>
      <Box>
        {/* Always render AuthStatus, but it will only display content in specific states */}
        <AuthStatus />

        {/* Conditionally render routes or redirect based on authentication state */}
        <Routes>
          {auth.isAuthenticated ? (
            <>
              {/* Protected routes for authenticated users */}
              <Route path="/chatbot" element={<ChatBot />} />
              <Route path="/dashboard" element={<AppDrawer />} />
              <Route path="/" element={<AppDrawer />} />
              <Route path="/signin-callback" element={<AuthButton />} />
              {/* Redirect or show authenticated user's default view */}
              {/* Consider adding a redirect here for "/" to "/dashboard" */}
            </>
          ) : (
            <>
              {/* Public routes or redirect to sign-in page */}
              <Route path="*" element={<AuthButton />} />
              {/* Consider redirecting authenticated routes to a sign-in page or showing a public default view */}
            </>
          )}
        </Routes>
      </Box>
    </Router>
  );
}

export default function ToggleColorMode() {
  const [mode, setMode] = React.useState('dark');

  // useMemo to create the theme only when mode changes
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          // ... any other theme customizations
        },
      }),
    [mode], // Dependency array with mode
  );

  // useEffect to apply the theme's background color to the body
  React.useEffect(() => {
    document.body.style.backgroundColor = theme.palette.background.default;
    // Optional: Clean up the style on unmount
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, [theme]); // Dependency array with theme

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [], // Empty dependency array means this useMemo will only run on mount
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}