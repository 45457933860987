import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const username = localStorage.getItem('username');

function MeetingSummary({ meeting_uuid, setAudioId }) {
  const [summary, setSummary] = useState('');
  const [meetingName, setMeetingName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);


  useEffect(() => {
    const fetchSummary = () => {
      setIsLoading(true);
      fetch(`https://${apiEndpoint}/getsummary/?meeting_uuid=${meeting_uuid}&username=${username}`)
        .then((response) => response.json())
        .then((data) => {
          const markdownText = data.summary;

          setSummary(markdownText);

          // Update the meeting name
          const fetchedMeetingName = data.info.meeting_name;
          setMeetingName(fetchedMeetingName);
        })
        .catch((error) => console.error(error))
        .finally(() => setIsLoading(false));
    };

    if (meeting_uuid) {
      fetchSummary();
    }
    const interval = setInterval(fetchSummary, 60000);
    return () => clearInterval(interval);
  }, [meeting_uuid]);

  // Custom component for rendering time buttons
  const TimeButton = ({ children, onClick }) => (
    <Button variant="text" size="small" color="primary" onClick={onClick}>{children}</Button>
  );

  const fecthRecording = (value) => {
    const timeWithoutColons = value.replace(/:/g, "");
    fetch(`https://${apiEndpoint}/recordingbytime/?meeting_uuid=${meeting_uuid}&time=${timeWithoutColons}&username=${username}`)
    .then((response) => response.json())
    .then((data) => {
      setAudioId(data.recording)
    })
    .catch((error) => console.error(error))
    .finally(() => setIsLoading(false));
  };


  // Function to replace time texts with TimeButton components
  const transformText = (text) => {
    // const timeRegex1 = /\(\d{2}:\d{2}:\d{2} - \d{2}:\d{2}:\d{2}\)/g;
    // const timeRegex2 = /\d{2}:\d{2}:\d{2} - \d{2}:\d{2}:\d{2}/g;
    // const timeRegex3 = /\(\d{2}:\d{2}:\d{2}\)/g;
    const timeRegex4 = /\d{2}:\d{2}:\d{2}/g;

    // const combinedRegex = new RegExp(`(${timeRegex1.source})|(${timeRegex2.source})|(${timeRegex3.source})|(${timeRegex4.source})`, 'g');
    const combinedRegex = new RegExp(`(${timeRegex4.source})`, 'g');

    let parts = [];
    let lastIndex = 0;
    
    text.replace(combinedRegex, (match, p1, offset) => {
      // Add the text before the match
      parts.push(text.slice(lastIndex, offset));
      // Add the TimeButton component
      parts.push(<TimeButton key={offset} onClick={() => fecthRecording(match)}>{match}</TimeButton>);
      // Update lastIndex to the end of the current match
      lastIndex = offset + match.length;
    });

    // Add any remaining text after the last match
    parts.push(text.slice(lastIndex));

    return parts;
  };

  // Higher-order function to create custom components
  const createCustomComponent = (Tag) => {
    return ({ children, ...props }) => (
      <Tag {...props}>
        {React.Children.map(children, (child) =>
          typeof child === 'string' ? transformText(child) : child
        )}
      </Tag>
    );
  };

// Object containing custom components
const components = {
  h1: createCustomComponent('h1'),
  h2: createCustomComponent('h2'),
  h3: createCustomComponent('h3'),
  h4: createCustomComponent('h4'),
  em: createCustomComponent('em'),
  li: createCustomComponent('li'),
  p: createCustomComponent('p'),
  strong: createCustomComponent('strong'),
  // Add more tags here if needed
};
  return (
    <Box sx={{ bgcolor: 'background.default', p: 2, flexGrow: 1, }} aria-busy={isLoading}>
      {isLoading && <Box sx={{ width: '100%' }}><LinearProgress /></Box>}
      {meetingName && (<Paper elevation={3} sx={{ padding: theme => theme.spacing(2),  flexGrow: 1 }}>
        <Typography
            variant="h4"
            noWrap
            align="center" 
            sx={{
              mr: 2,
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {meetingName}
          </Typography>
        <Divider variant="middle"/>
        <ReactMarkdown components={components}>{summary}</ReactMarkdown>
      </Paper>)}
    </Box>
  );
}

export default MeetingSummary;
