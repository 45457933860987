import * as React from 'react';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SaveIcon from '@mui/icons-material/Save';
import MergeIcon from '@mui/icons-material/Merge';

export default function SpeedDialTooltipOpen({toggleRecorder, mergeSelected}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box sx={{ transform: 'translateZ(0px)', flexGrow: 1 }}>
      <Backdrop open={false} />
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        <SpeedDialAction
        key="NewRecording"
        icon={<SaveIcon />}
        tooltipTitle="Start Meeting"
        tooltipOpen
        onClick={toggleRecorder}
        />
        <SpeedDialAction
        key="MergeMeetings"
        icon={<MergeIcon />}
        tooltipTitle="Merge Selected"
        tooltipOpen
        onClick={mergeSelected}
        />
      </SpeedDial>
    </Box>
  );
}